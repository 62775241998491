<template>
  <div>
    <div class="py-[4rem] bg-[#FFFAFB]">
      <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div class="text-center mb-8">
          <h1 class="text-[32px] md:text-[64px] text-[#15182A] leading-[40px] md:leading-[70.892px] mb-5 font-bold">
            If opportunity doesn’t <br class="hidden md:inline-block" />
            knock, build a door
          </h1>
          <p class="text-[20px] text-[#1C1C1E] font-medium mb-8">
            CareerBuddy acts as a matchmaker connecting companies to high-quality remote talents, particularly focusing on
            <br class="hidden md:inline-block" />
            African professionals. We help identify, hire and manage employees who contribute to your organization.
          </p>
        </div>
        <br />

        <div class="grid grid-cols-1 sm:grid-cols-2 md:flex justify-between gap-4 md:gap-x-8 mb-8">
          <div class="h-[360px] md:flex-1 rounded-lg bg-cover bg-center image-container group" :style="{ backgroundImage: `url(${images.one})` }">
            <div class="w-full h-full bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg"></div>
          </div>

          <div class="grid grid-cols-2 sm:grid-cols-1 gap-4 md:w-[176px]">
            <div
              class="h-[170px] md:h-[170px] rounded-lg bg-cover bg-center image-container group"
              :style="{ backgroundImage: `url(${images.two})` }"
            >
              <div class="w-full h-full bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg"></div>
            </div>
            <div
              class="h-[170px] md:h-[170px] rounded-lg bg-cover bg-center image-container group"
              :style="{ backgroundImage: `url(${images.three})` }"
            >
              <div class="w-full h-full bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg"></div>
            </div>
          </div>

          <div class="h-[360px] md:flex-1 rounded-lg bg-cover bg-center image-container group" :style="{ backgroundImage: `url(${images.four})` }">
            <div class="w-full h-full bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg"></div>
          </div>
          <div class="h-[360px] md:flex-1 rounded-lg bg-cover bg-center image-container group" :style="{ backgroundImage: `url(${images.five})` }">
            <div class="w-full h-full bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg"></div>
          </div>
        </div>
        <br />
        <br />

        <div class="text-center">
          <h1 class="text-[32px] text-black font-bold leading-[70.892px] mb-[4rem]">What we offer</h1>
        </div>

        <div class="md:grid grid-cols-3 gap-8 pb-8">
          <div class="px-[45px] py-[62px] rounded-lg bg-[#9CA5E0]/[0.4]">
            <h3 class="text-[20px] mb-3 leading-[34px] font-semibold min-h-40">Care Deeply</h3>
            <p class="text-base">
              We genuinely care about our work, our clients, and our community. Our passion drives us to make a real difference in people's careers
              and lives.
            </p>
          </div>

          <div class="px-[45px] py-[62px] rounded-lg bg-[#F8E9DF]/[0.4]">
            <h3 class="text-[20px] mb-3 leading-[34px] font-semibold min-h-40">Unwavering Integrity</h3>
            <p class="text-base">Our integrity is non-negotiable. It's the backbone of everything we do, no matter the circumstances.</p>
          </div>

          <div class="px-[45px] py-[62px] rounded-lg bg-[#DCE5E3]/[0.4]">
            <h3 class="text-[20px] mb-3 leading-[34px] font-semibold min-h-40">Own Outcomes</h3>
            <p class="text-base">We take full responsibility for our actions and results. No passing the buck – we're here to make things happen.</p>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>

    <div class="pt-[4rem] bg-white">
      <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div class="md:flex gap-16">
          <div class="w-[600px] pb-8 mb-8">
            <span class="inline-block mb-[24px] h-[3px] w-[101px] bg-black"></span>
            <h1 class="text-[32px] text-black leading-[50.892px] mb-0 font-bold">Our Story</h1>
            <p class="text-[#1C1C1E] text-[20px]">Transforming businesses and lives</p>
          </div>
          <div class="space-y-8">
            <p class="text-[#1C1C1E] text-base">
              We're creating a smarter way to hire by blending the latest technology and a personal touch to help companies hire and manage the right
              talents.
            </p>
            <p class="text-[#1C1C1E] text-base font-bold mt-8 mb-4">Vision</p>
            <p class="text-[#1C1C1E] text-base">
              To be the first choice platform that empowers African professionals to build successful, fulfilling careers while creating a vibrant,
              supportive community that drives innovation and economic growth across the continent.
            </p>
            <p class="text-[#1C1C1E] text-base leading-relaxed">
              At CareerBuddy, we're on a mission to help African professionals find truly rewarding work, excel at their current jobs and build
              meaningful connections. As someone who started his career with AIESEC, a global student run organization and subsequently worked at a
              high-growth startup, I saw firsthand the difference between people who feel disconnected from their jobs and people who find a deep
              sense of fulfillment from their work.
            </p>

            <p class="text-[#1C1C1E] text-base mt-6 leading-relaxed">
              That's why we created CareerBuddy. We want to give African professionals the tools and community to discover work that energizes them,
              not drains them. To find roles where they can apply their unique talents and perspectives. To connect with like-minded peers who inspire
              and support them.
            </p>

            <p class="text-[#1C1C1E] text-base mt-6 leading-relaxed">
              Remote work has made building those vital professional relationships more challenging. Water cooler chats and impromptu lunches with
              coworkers are largely a thing of the past. At CareerBuddy, we're leveraging technology to recreate those serendipitous connections in a
              digital world.
            </p>

            <p class="text-[#1C1C1E] text-base mt-6 leading-relaxed">
              We're providing resources to help you navigate your career journey with confidence. Whether it's advice on negotiating a raise,
              switching industries, or starting your own venture, we've got your back. Our goal is to empower you to take control of your professional
              life and find work that's truly meaningful.
            </p>

            <p class="text-[#1C1C1E] text-base mt-6 leading-relaxed font-medium">
              The African professional landscape is brimming with talent and potential. We're here to help unleash it. Join us in building a community
              where African professionals can thrive, connect, and make their mark on the world.
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />

        <!-- <div class="mt-8">
          <div class="text-center">
            <h1 class="text-[32px] text-[#000000] leading-[70.892px] mb-[5rem]">Our Staff</h1>
          </div>
          <div class="grid grid-cols-2 md:grid-cols-5 gap-8 pb-8">
            <div class="flex flex-col items-center">
              <img src="@/assets/images/staff.png" class="mb-2 w-[176px] h-[176px]" alt="" />
              <div class="text-center">
                <h3 class="text-[#121212] text-[22px] font-bold">Saviour Inyang</h3>
                <small class="text-[#1C1C1E] text-[14px]">Product Designer</small>
              </div>
            </div>

            <div class="flex flex-col items-center">
              <img src="@/assets/images/staff.png" class="mb-2 w-[176px] h-[176px]" alt="" />
              <div class="text-center">
                <h3 class="text-[#121212] text-[22px] font-bold">Saviour Inyang</h3>
                <small class="text-[#1C1C1E] text-[14px]">Product Designer</small>
              </div>
            </div>

            <div class="flex flex-col items-center">
              <img src="@/assets/images/staff.png" class="mb-2 w-[176px] h-[176px]" alt="" />
              <div class="text-center">
                <h3 class="text-[#121212] text-[22px] font-bold">Saviour Inyang</h3>
                <small class="text-[#1C1C1E] text-[14px]">Product Designer</small>
              </div>
            </div>

            <div class="flex flex-col items-center">
              <img src="@/assets/images/staff.png" class="mb-2 w-[176px] h-[176px]" alt="" />
              <div class="text-center">
                <h3 class="text-[#121212] text-[22px] font-bold">Saviour Inyang</h3>
                <small class="text-[#1C1C1E] text-[14px]">Product Designer</small>
              </div>
            </div>

            <div class="flex flex-col items-center">
              <img src="@/assets/images/staff.png" class="mb-2 w-[176px] h-[176px]" alt="" />
              <div class="text-center">
                <h3 class="text-[#121212] text-[22px] font-bold">Saviour Inyang</h3>
                <small class="text-[#1C1C1E] text-[14px]">Product Designer</small>
              </div>
            </div>

            <div class="flex flex-col items-center">
              <img src="@/assets/images/staff.png" class="mb-2 w-[176px] h-[176px]" alt="" />
              <div class="text-center">
                <h3 class="text-[#121212] text-[22px] font-bold">Saviour Inyang</h3>
                <small class="text-[#1C1C1E] text-[14px]">Product Designer</small>
              </div>
            </div>

            <div class="flex flex-col items-center">
              <img src="@/assets/images/staff.png" class="mb-2 w-[176px] h-[176px]" alt="" />
              <div class="text-center">
                <h3 class="text-[#121212] text-[22px] font-bold">Saviour Inyang</h3>
                <small class="text-[#1C1C1E] text-[14px]">Product Designer</small>
              </div>
            </div>
          </div>
        </div> -->
        <br />
        <br />
        <br />

        <div class="text-center">
          <h1 class="text-[32px] text-[#000000] leading-[70.892px] mb-8 font-bold">Our Partners</h1>
        </div>
        <br />
        <ScrollingLogos :logos="companyLogos" />
        <br />
      </div>
    </div>

    <div class="pt-[4rem] bg-white mb-10">
      <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div class="bg-[#1A1A1A] px-8 py-[5rem] rounded-xl flex justify-center relative overflow-hidden group">
          <!-- Accent circles for visual interest -->
          <div
            class="absolute -right-20 -top-20 w-40 h-40 rounded-full bg-[#00F5D4]/10 group-hover:bg-[#00F5D4]/20 transition-colors duration-300"
          ></div>
          <div
            class="absolute -left-20 -bottom-20 w-40 h-40 rounded-full bg-[#FDE442]/10 group-hover:bg-[#FDE442]/20 transition-colors duration-300"
          ></div>

          <div class="text-center relative z-10">
            <h1 class="text-white text-[28px] font-bold leading-[45px] mb-6">Ready to Start Working Together?</h1>
            <p class="leading-[22px] text-[#ADADAD] mb-8 max-w-[600px] mx-auto">
              At CareerBuddy, we're not just a recruitment agency, we're your best choice for discovering top talent worldwide. Our track record of
              linking global companies with premium candidates has made us a top choice for hiring success
            </p>

            <a
              href="#"
              class="font-medium text-[17px] text-[#1A1A1A] inline-block px-10 py-3 rounded-xl bg-white hover:bg-black/90 transition-colors duration-300"
            >
              Partner with us
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import ScrollingLogos from '@/components/common/ScrollingLogos.vue';
import remotePassLogo from '@/assets/images/remotepassLogo.svg';
import piggyVestLogo from '@/assets/images/piggyvestLogo.svg';
import moniePointLogo from '@/assets/images/moniepointLogo.svg';
import flutterwaveLogo from '@/assets/images/flutterwaveLogo.svg';
import autocheckLogo from '@/assets/images/autochek.png';
import bambooLogo from '@/assets/images/bamboo.png';
import codematicLogo from '@/assets/images/codematic.png';
import sabiLogo from '@/assets/images/sabi.png';
import techcabal from '@/assets/images/techcabal.png';

const images = {
  one: require('@/assets/images/ab1.png'),
  two: require('@/assets/images/ab2.png'),
  three: require('@/assets/images/ab3.png'),
  four: require('@/assets/images/ab4.png'),
  five: require('@/assets/images/ab5.png'),
};

const companyLogos = ref([
  { src: remotePassLogo, alt: 'RemotePass' },
  { src: piggyVestLogo, alt: 'PiggyVest' },
  { src: moniePointLogo, alt: 'MoniePoint' },
  { src: flutterwaveLogo, alt: 'Flutterwave' },
  { src: autocheckLogo, alt: 'Autocheck' },
  { src: bambooLogo, alt: 'Bamboo' },
  { src: codematicLogo, alt: 'Codematic' },
  { src: sabiLogo, alt: 'Sabi' },
  { src: techcabal, alt: 'TechCabal' },
]);
</script>

<style lang="scss" scoped>
.bg-image {
  background-image: url('../../assets/icons/vector-bg.svg');
  background-repeat: no-repeat;
}

.image-container {
  transition: transform 0.3s ease-in-out;
}

.image-container:hover {
  transform: scale(1.02);
}
</style>
